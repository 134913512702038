import { useState } from 'react';

const UIInput = ({
    label,
    className,
    errorText = '',
    type = 'text',
    maxLength = '',
    error = false,
    handleChange,
    value,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [onFocus, setOnFocus] = useState(false);
    const [initialType, setInitialType] = useState(true);

    const onChange = (e) => {
        setInputValue(e.target.value);
        handleChange(e.target.value);
    };

    return (
        <div
            className={`relative flex border rounded overflow-hidden w-full h-[55px] shadow-inner 
            ${error ? 'border-red-500 animate-shake' : ''}
            ${onFocus ? 'border-primary' : ''}
            ${className}`}>
            <label
                className={`absolute flex h-full top-2 left-2 z-1 mb-2 transition-all text-[#8F9BB3] ${
                    onFocus || inputValue !== ''
                        ? 'top-2 left-2 text-xxs font-bold'
                        : 'top-4 left-4 text-sm'
                }`}>
                {label}
            </label>
            {error && (
                <div className="absolute flex items-center justify-center w-6 h-6 text-xs text-white -translate-y-1/2 bg-red-500 rounded-full right-3 top-1/2">
                    !
                </div>
            )}
            {type === 'password' && (
                <div className="absolute z-10 -translate-y-3 border-none cursor-pointer stroke-slate-400 we right-3 top-1/2">
                    <input
                        tabIndex="-1"
                        type="checkbox"
                        onClick={() => setInitialType(!initialType)}
                        className="absolute z-10 p-2 mt-1 ml-1 appearance-none cursor-pointer "
                        style={{ WebkitAppearance: 'none' }}
                    />
                    {initialType ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            // stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            // stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    )}
                </div>
            )}

            <input
                onFocus={() => setOnFocus(true)}
                onBlur={() => setOnFocus(false)}
                className="absolute appearance-none w-full h-full p-4 text-[#222B45] px-2 pb-2 text-[15px] bg-transparent border-none outline-none z-2"
                type={initialType ? type : 'text'}
                maxLength={maxLength}
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default UIInput;

export const talent = [
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354387/121%20web/121%20Talent/talent08_lysmat.jpg',
            name: 'Alejandro Trípoli',
            charge: 'Fundador y Director General',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent09_iyyzff.jpg',
            name: 'Gonzalo Calmet ',
            charge: 'Fundador y Director Creativo Ejecutivo',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354389/121%20web/121%20Talent/talent07_kqfv6q.jpg',
            name: 'Benjamín Edwards',
            charge: 'Fundador y Director de Negocios',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent06_z4ygi0.jpg',
            name: 'Clara Ferro',
            charge: 'Directora de Contenidos',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/Lucas_Bargen_aso1lc.png',
            name: 'Lucas Bargen',
            charge: 'DCG',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent05_qlxrwn.png',
            name: 'Jackie Strancman',
            charge: 'Directora de Cuentas',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent02_ye9voc.jpg',
            name: 'Jojo Penas',
            charge: 'Director de Contenidos',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent04_ketznr.jpg',
            name: 'Vanessa Ortiz',
            charge: 'Directora de Nuevos Negocios',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent03_lgx9uh.png',
            name: 'Juan Camilo Lema',
            charge: 'Director de Tecnología',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/talent10_n0flma.png',
            name: 'Luis Ruiz',
            charge: 'Director de Producción',
        },
    },
    // {
    //     attributes: {
    //         image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1670354388/121%20web/121%20Talent/Fiorella_Figueroa_rj6v45.png',
    //         name: 'Fiorella Figueroa',
    //         charge: 'Directora de RR.SS.',
    //     },
    // },
];

export const servicios = [
    {
        attributes: {
            name: 'BIG IDEAS',
            color: '#F2BF57',
            textcolor: '#1C3D6D',
            order: 'md:order-1 order-2',
            description:
                'Ideamos campañas de comunicación para generar sorpresa y atraer la atención en múltiples medios y plataformas.',
        },
    },
    {
        attributes: {
            name: 'CONSULTORÍA ESTRATÉGICA',
            color: '#1C3D6D',
            textcolor: '#8bbcc2',
            order: 'md:order-2 order-4',
            description:
                'Desarrollamos estrategias potentes para tu marca, para tu plan de comunicación y para tus redes scoiales.',
        },
    },
    {
        attributes: {
            name: 'GESTIÓN DE REDES',
            color: '#8bbcc2',
            textcolor: '#1C3D6D',
            order: 'md:order-3 order-1',
            description:
                'Creamos contenidos para tus redes y las gestionamos para construir enagagement y mejorar el sentiment.',
        },
    },
    {
        attributes: {
            name: 'ANALÍTICA E INSIGHT',
            color: '#c886a9',
            textcolor: '#1C3D6D',
            order: 'md:order-4 order-3',
            description:
                'Análisis y reporting, sentiment y analítica predictiva, Machine Learning aplicado a los negocios.',
        },
    },
];
